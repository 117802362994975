import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Art`}</strong>{` activities for this age group afford children a chance for choice, exploration, experimentation, and imaginative expression in a supportive atmosphere. Opportunities are provided to explore color, line, and form and to discover the effects of various media.`}</p>
    <p><strong parentName="p">{`Language Arts`}</strong>{` activities follow a whole language approach along with instruction in phonics, enhancing the child's receptive and expressive language through finger plays, rhymes, songs, and discussions. This interactive, hands-on technique allows the children to more fully appreciate the activities they are learning about. Reading readiness is fostered through practice with letters, sounds, and whole word recognition.`}</p>
    <p><strong parentName="p">{`Math`}</strong>{` Concepts are presented through the activities involving sorting, comparing, and ordering. Playful activities develop skill in rote counting, numerical recognition, sets, and 1-to-1 correspondence.`}</p>
    <p><strong parentName="p">{`Music, movement, and dramatics`}</strong>{` experiences are used to help children develop language and pre-reading skills and encourage imagination and spontaneous play. Children gain skill in body control, rhythm, following directions, and group coordination.`}</p>
    <p><strong parentName="p">{`Nutrition/foods`}</strong>{` experience helps children learn about group cooperation as well as math and science concepts such as weight, measures, and changes of matter from one form to another. Adhering to a recipe also helps develop an understanding of how to follow directions in sequence.`}</p>
    <p><strong parentName="p">{`Social Studies`}</strong>{` for this age group gradually broadens a child's concept of the world by focusing on self, home, family, transportation, animals, and community.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      